import React from 'react'
import PropTypes from 'prop-types'
import '../scss/login-banner.scss'
import CTAButton from '../common/CTAButton'
import { Link } from 'gatsby'

export default function LoginBanner(props) {
  const { loginInfo } = props
  return (
    <React.Fragment>
      <div className="sb-mobile-header">
        <img src="/img/koble/koble_logo_white_new.png" alt="logo" />
      </div>
      <div className="d-flex login-banner-container column-reverse-in-mobile">
        <div className="left-container d-flex align-items-center justify-content-center">
          <img
            src="/img/koble/koble_logo_white_new.png"
            alt="logo"
            className="logo"
          />
        </div>

        <div className="create-account">
          <div className="ca-card">
            <div className="ca-title">Log in to your account</div>
            <div className="ca-signup-with-google">
              <CTAButton
                type="cta"
                text="Continue with Google"
                leftIcon="img/koble/google_icon.png"
                rightIcon="img/koble/arrow_icon_straight.png"
                link="https://api.koble.ai/auth/google-investor?redirect_url=https%3A%2F%2Finvestor.koble.ai"
              />
            </div>
            <div className="ca-signup-with-email">
              <CTAButton
                type="cta"
                text="Continue with email"
                bgColor="#6E6BFF"
                rightHoverIcon="/img/koble/arrow_purple_icon.svg"
                link="https://investor.koble.ai/login?with-email=1"
                rightIcon="/img/koble/arrow_icon_straight.png"
              />
            </div>
            <div className="ca-login">
              Don’t have a Koble account?{' '}
              <Link to="/signup">
                <span className="signup-link">Click here to sign up</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

LoginBanner.propTypes = {
  height: PropTypes.number,
  loginBanner: PropTypes.object
}
