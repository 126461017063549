import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import LoginBanner from '../components/koble/LoginBanner'

// eslint-disable-next-line
export const LoginPageTemplate = ({ loginInfo }) => {
  return (
    <div>
      <LoginBanner loginInfo={loginInfo} />
    </div>
  )
}

LoginPageTemplate.propTypes = {
  loginInfo: PropTypes.array
}

const LoginPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <LoginPageTemplate loginInfo={post.frontmatter.loginInfo} />
    </Layout>
  )
}

LoginPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default LoginPage

export const signupPageQuery = graphql`
  query LoginPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        loginInfo {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, height: 770)
            }
          }
        }
      }
    }
  }
`
